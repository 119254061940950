import React from 'react'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Awards({key, index, ...props}) {

  const section = props.awards

  return (
    <section id={section.fieldGroupName + index}  className="my-20 md:my-36">
      <div className="container">
        <div className="grid grid-cols-12 gap-8 gap-y-8 items-start">
          {section.awards.map((award, i) => (
            <FadeInWhenVisible key={`award${i}`} transition={{delay: ((i * .1) + .05)}} className="col-span-6 md:col-span-3 text-center">
              <Image data={award.image} className="w-[120px] h-[120px] mx-auto mb-8" objectFit="contain" objectPosition="center" />
              <h6 className="text-h6 mb-2">{award.heading}</h6>
              <div className="text-navy text-opacity-60">{award.year}</div>
            </FadeInWhenVisible>
          ))}
        </div>
      </div>
    </section>
  )
}
